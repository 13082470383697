<template>
  <div class="body-main">
    <el-image class="banner" :src="require('@/assets/index/icon-005.png')" alt></el-image>
    <div class="main">
      <bread-crumb :list="breadCrumbList"></bread-crumb>
      <hot-city :list="cityList" @clickCityItem="clickCityItem"></hot-city>
      <div class="content">
        <div class="left">
          <car-list :list="searchCarList"></car-list>
          <pagination background @current-change="handleCurrentChange" :current-page.sync="searchCarRequest.page" :page-size="searchCarRequest.per_page" layout="prev, pager, next, jumper" :total="totalPage"></pagination>
        </div>
        <!-- <div class="right">
          <router-link class="right-item" v-for="(item, index) in adData" :key="index" :to="{
              path: '/travel-route/detail',
              query: { route_bn: item.route_bn }
            }">
            <el-image :src="item.ad_img" alt></el-image>
              <div class="box-4-item-desc">{{ item.route_title }}</div>
              <div class="box-4-item-price">
                <span class="price">{{ item.route_price }}</span>起
              </div>
          </router-link>
        </div> -->
        <div class="right">
          <div class="box-4">
            <router-link
              :to="{
                path: '/travel-route/detail',
                query: { route_bn: item.route_bn }
              }"
              class="box-4-item"
              v-for="(item, index) in adData"
              :key="index"
            >
              <div>
                <img :src="item.ad_img" alt class="box-4-item-icon" />
              </div>
              
              <div class="box-4-item-desc" :title="item.route_title">
                {{ item.route_title }}
              </div>
              <div class="box-4-item-price">
                <span class="price">{{ item.route_price }}</span>起
              </div>
            </router-link>
          </div>
        </div>     
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from '@/components/BreadCrumb'
import pagination from '@/components/pagination'
import CarList from '@/components/CarList'
import HotCity from './components/HotCity'
import {
  orderCarProductSearch,
  orderCarTypeSearch,
  searchCar,
  orderCarCitySearch,
  routeTagDetailSearch,
} from '@/api/index'
export default {
  name: 'rentCarList',
  components: {
    BreadCrumb,
    pagination,
    CarList,
    HotCity,
  },
  data() {
    return {
      breadCrumbList: [
        {
          name: '车导服务',
          path: '',
          query: '',
        },
      ],
      totalPage: 0,
      orderCarProductSearchList: [],
      orderCarTypeSearchList: [],
      searchCarRequest: {
        page: 1,
        per_page: 5,
        currency: 1,
        city_id: [],
      },
      searchCarList: [],
      cityList: [],
      adData: [],
      listInitFlag: 0
    }
  },
  computed: {
    currency() {
      return this.$store.state.home.currency.currency_id
    },
  },
  watch: {
    currency: {
      handler(val) {
        this.searchCarRequest.currency = Number(val)
        if (this.listInitFlag === 1) {
          this.searchCarFunc()
        }
      },
      immediate: true,
    },
  },
  created() {
    this.searchCarFunc()
    this.orderCarCitySearchFunc()
    this.getAdData()
  },
  methods: {
    // 子页-右侧广告
    getAdData() {
      routeTagDetailSearch({ tagid: 'tag_type_101' }).then((res) => {
        this.adData = res.data
      })
    },
    // 租车列表
    searchCarFunc() {
      searchCar(this.searchCarRequest).then((response) => {
        response.data.forEach(item => {
          item.car_type.sort((a, b) => {
            return a.car_luggage_num - b.car_luggage_num;
          })
        });
        this.searchCarList = response.data
        this.totalPage = response.total_nums
        this.listInitFlag = 1
      })
    },
    // 城市列表
    orderCarCitySearchFunc() {
      orderCarCitySearch({}).then((response) => {
        response.data.sort((a,b)=>{
                return a.city_nm.localeCompare(b.city_nm,"zh");
            }
        );
        this.cityList = response.data
      })
    },
    // 跳转指定页面
    handleCurrentChange(val) {
      this.searchCarRequest.page = val
      this.searchCarFunc()
    },
    // 点击城市item
    clickCityItem(item) {
      this.searchCarRequest.page = 1
      if (item.city_id) {
        this.searchCarRequest.city_id = [item.city_id]
      } else {
        this.searchCarRequest.city_id = []
      }
      this.searchCarFunc()
    },
  },
}
</script>

<style lang="scss" scoped>
.body-main {
  width: 100%;
  background: rgba(245, 245, 245, 1);
  .main {
    overflow: hidden;
    width: 1200px;
    margin: 0 auto;
    .content {
      display: flex;
      .left {
        width: 960px;
        margin-right: 15px;
      }
      // .right {
      //   flex: 1;
      //   font-size: 0;
      //   & > * {
      //     display: inline-block;
      //     cursor: pointer;
      //     margin-bottom: 10px;
      //   }
      // }
      .right {
        width: 254px;
        margin-top: 41px;
        .box-3 {
          width: 100%;
          height: 170px;
          margin-bottom: 20px;
        }
        .box-4 {
          background: rgba(255, 255, 255, 1);
          padding: 10px;
          &-title {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: rgba(51, 51, 51, 1);
            line-height: 24px;
            margin: 2px 0 10px;
          }
          &-item {
            display: inline-block;
            border-bottom: 2px solid rgba(245, 245, 245, 1);
            margin-bottom: 14px;
            &:last-child {
              border: none;
            }
            &-icon {
              height: 135px;
              width: 100%;
              margin-bottom: 10px;
            }
            &-desc {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: rgba(102, 102, 102, 1);
              line-height: 18px;
              margin-bottom: 15px;
              height: 165px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 9; // 多行在这里修改数字即可，这里显示9行
              overflow:hidden;
              -webkit-box-orient: vertical;
            }
            &-price {
              .price {
                color: rgba(255, 0, 0, 1);
              }
              padding-bottom: 15px;
              text-align: right;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
              line-height: 24px;
            }
          }
        }
      }      
    }
  }
}
</style>
