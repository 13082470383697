<template>
  <div class="hot-city">
    <div class="hot-city-title">热销城市</div>
    <div class="hot-city-content">
      <div
        :class="['hot-city-content-item', ins === 0 ? 'active' : '']"
        @click="clickCityItem({city_id:''}, 0)"
      >全部</div>
      <div
        :class="['hot-city-content-item', ins === index + 1 ? 'active' : '']"
        @click="clickCityItem(item, index + 1)"
        v-for="(item, index) in list"
        :key="index"
      >{{item.city_nm}}</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HotCity',
    data() {
      return {
        ins: 0
      };
    },
    props: {
      list: {
        type: Array
      }
    },
    methods: {
      clickCityItem(item, index) {
        this.ins = index;
        this.$emit('clickCityItem', item);
      }
    }
  };
</script>

<style lang="scss" scoped>
.hot-city {
  min-height: 104px;
  margin-bottom: 30px;
  padding: 15px 0;
  background: rgba(255, 255, 255, 1);
  border-top: 4px solid rgba(111, 15, 149, 1);
  display: flex;
  align-items: baseline;
  &-title {
    padding: 0 20px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
    height: 30px;
    line-height: 30px;
    margin: 3px 5px;
  }
  &-content {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    padding-right: 50px;
    &-item {
      cursor: pointer;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
      padding: 0 5px;
      height: 30px;
      margin: 3px 5px;
      text-align: center;
      &.active,
      &:hover {
        background: rgba(91, 9, 123, 1);
        color: #ffffff;
      }
    }
  }
}
</style>